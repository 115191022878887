import React from 'react';

function MsgProgramada() {
    return (
        <div className="msg-body">
            <h1>aprenda mais rapido</h1>
        </div>
    );
};

export default MsgProgramada;